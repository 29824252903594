import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import MainLogo from "../MainLogo.png";
import NavBar from "./NavBar";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  Link,
} from "react-router-dom";

import { Image, Col, Row, Container } from "react-bootstrap";

import { isMobileOnly, isIE, isEdge, isSafari } from "react-device-detect";
import Footer from "./Footer";

import chatIcon from "../coffeeIcon.jpg";
import plainSconeIcon from "../PlainScone.png";
import buildingWeb from "../buildingWeb.jpg";
import logoTilt from "../logoTilt.jpg";
import deliveryIcon from "../deliveryIcon.jpg";
import pickupIcon from "../pickupIcon.jpg";

import * as firebase from "firebase";

class PrintOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      orderId: this.props.match.params.id,
      orderSheet: null,
      sconeMenu: null,
    };
  }

  componentWillMount() {}

  componentDidMount() {
    firebase
      .database()
      .ref(`orderSheets/${this.state.orderId}`)
      .once("value", (snapshot) => {
        if (snapshot.val()) {
          this.setState({ orderSheet: snapshot.val() });
        }
      });

    firebase
      .database()
      .ref("sconeMenu")
      .once("value", (snapshot) => {
        if (snapshot.val()) {
          this.setState({ sconeMenu: snapshot.val() });
        }
      });
  }

  componentWillUnmount() {}

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
  }

  onGetTotalAmount(items) {
    let result = Object.values(items).reduce((a, b) => a + b.amount, 0);
    return result;
  }

  getOrderItemsByCategory(items) {
    const { sconeMenu } = this.state;
    if (!sconeMenu) return {};

    const categories = {};

    // 메뉴 이름 자르기 함수 (10자로 수정)
    const truncateName = (name) => {
      return name.length > 10 ? name.substring(0, 10) + "..." : name;
    };

    // 카테고리 한글 변환 함수
    const getCategoryInKorean = (category) => {
      const categoryMap = {
        scone: "스콘",
        financier: "휘낭시에",
        roll: "롤",
        cream_bun: "크림번",
        cookie: "쿠키",
        additional: "추가상품",
      };
      return categoryMap[category] || category;
    };

    // items의 각 아이템에 대해
    Object.entries(items).forEach(([itemId, itemData]) => {
      const menuItem = sconeMenu[itemId];
      if (menuItem) {
        const category = getCategoryInKorean(menuItem.category);
        if (!categories[category]) {
          categories[category] = [];
        }
        categories[category].push({
          id: itemId,
          name: truncateName(menuItem.name),
          amount: itemData.amount,
        });
      }
    });

    return categories;
  }

  renderOrderTable(order, orderIndex) {
    const categorizedItems = this.getOrderItemsByCategory(order.items);
    const categories = Object.keys(categorizedItems);

    return (
      <table className="sheet-table" id="table">
        <thead>
          <tr>
            <th id="th-title" colSpan="25">
              택배주문서
            </th>
          </tr>
        </thead>
        <tbody>
          {/* 카테고리 헤더 */}
          <tr>
            <td id="td" colSpan="3">
              주문자 정보
            </td>
            {categories.map((category) => (
              <td
                id="td"
                key={category}
                colSpan={categorizedItems[category].length}
              >
                {category}
              </td>
            ))}
            <td id="td" rowSpan="2">
              총 수량
            </td>
          </tr>

          {/* 메뉴 이름 헤더 */}
          <tr>
            <th id="th">No.</th>
            <th id="th">주문자</th>
            <th id="th">수령자</th>
            {categories.map((category) =>
              categorizedItems[category].map((item) => (
                <th id="th" key={item.id}>
                  {item.name}
                </th>
              ))
            )}
          </tr>

          {/* 주문 데이터 */}
          <tr>
            <td id="td-big-font">{orderIndex + 1 + 6}</td>
            <td id="td-1">{order.buyerName}</td>
            <td id="td-1">{order.addressTo.receiver}</td>
            {categories.map((category) =>
              categorizedItems[category].map((item) => (
                <td id="td-big-font" key={item.id}>
                  {item.amount}
                </td>
              ))
            )}
            <td id="td-big-font">{order.totalAmount}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    if (
      this.state.isLoading ||
      !this.state.orderSheet ||
      !this.state.sconeMenu
    ) {
      return <div></div>;
    }

    let orderSheetArr = Object.values(this.state.orderSheet["orders"]);

    return (
      <Grid container justify="center">
        <div className="print-page">
          {orderSheetArr.map((order, index) => {
            if (index % 4 === 0) {
              return (
                <div className="direction-row-div" key={index}>
                  <div className="subpage">
                    <div className="direction-column-div">
                      {orderSheetArr[index] && (
                        <div className="one-fourth">
                          {this.renderOrderTable(orderSheetArr[index], index)}
                          {/* additionalItems 테이블은 기존 코드 유지 */}
                        </div>
                      )}
                      {orderSheetArr[index + 1] && (
                        <div className="one-fourth">
                          {this.renderOrderTable(
                            orderSheetArr[index + 1],
                            index + 1
                          )}
                          {/* additionalItems 테이블은 기존 코드 유지 */}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="subpage">
                    <div className="direction-column-div">
                      {orderSheetArr[index + 2] && (
                        <div className="one-fourth">
                          {this.renderOrderTable(
                            orderSheetArr[index + 2],
                            index + 2
                          )}
                          {/* additionalItems 테이블은 기존 코드 유지 */}
                        </div>
                      )}
                      {orderSheetArr[index + 3] && (
                        <div className="one-fourth">
                          {this.renderOrderTable(
                            orderSheetArr[index + 3],
                            index + 3
                          )}
                          {/* additionalItems 테이블은 기존 코드 유지 */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </Grid>
    );
  }
}

export default PrintOrders;
